<template>
  <b-row>
    <b-col md="4">
      <app-input v-model="formdata.name" name="Firma Unvanı" label="Firma Unvanı:" placeholder="Firma Unvanı" rules="required" />
    </b-col>
    <b-col md="4">
      <app-input v-model="formdata.contractNumber" name="Sözleşme Numarası" label="Sözleşme Numarası:" placeholder="Sözleşme Numarası" rules="required" />
    </b-col>
    <b-col md="4">
      <app-date-input v-model="formdata.contractDate" name="Sözleşme Tarihi" label="Sözleşme Tarihi:" placeholder="Sözleşme Tarihi" rules="required" />
    </b-col>
    <b-col md="3">
      <app-select-input
        v-model="formdata.sector"
        name="Sektör"
        :options="sectors"
        :reduce="(option) => option._id"
        select_label="value"
        label="Sektör:"
        placeholder="Sektör Seçiniz..."
        rules="required"
      >
        <template #list-header>
          <div class="p-50 m-25">
            <app-button text="Sektör Ekle" icon="PlusIcon" block @click="$showAppSidebar('Sektör Ekle', sectorForm)" />
            <hr class="my-75" />
          </div>
        </template>
      </app-select-input>
    </b-col>
    <b-col md="3">
      <app-mask-input
        v-model="formdata.taxNo"
        input-mask="###########"
        name="Vergi/TC Numarası"
        type="number"
        label="Vergi/TC Numarası:"
        placeholder="Vergi/TC Numarası"
        rules="required|min:10"
      />
    </b-col>
    <b-col md="3">
      <app-input v-model="formdata.taxOffice" name="Vergi Dairesi" label="Vergi Dairesi:" placeholder="Vergi Dairesi" rules="required" />
    </b-col>
    <b-col md="3">
      <app-mask-input v-model="formdata.naceCode" input-mask="##.##.##" name="Nace Kodu" label="Nace Kodu:" type="number" placeholder="Nace Kodu..." rules="required|min:8" />
    </b-col>
  </b-row>
</template>

<script>
import sectorForm from "@/views/WebManagment/actions/optionData/sectors/sidebar.vue";
import { ValidationObserver } from "vee-validate";
import repeatedBranchForm from "./repeatedBranchForm.vue";

export default {
  components: { ValidationObserver, repeatedBranchForm },
  data() {
    return {
      formdata: {
        name: null,
        contractNumber: null,
        contractDate: null,
        sector: null,
        taxNo: null,
        naceCode: null,
        taxOffice: null,
      },
      sectorForm,
    };
  },
  computed: {
    sectors() {
      return this.$store.getters.sectors;
    },
  },
  watch: {
    formdata: {
      handler(item) {
        this.$emit("input", {
          ...item,
          contractDate: new Date(item.contractDate),
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch("getSectors");
  },
  destroyed() {
    this.$store.commit("setSectors", []);
  },
};
</script>

<style></style>
