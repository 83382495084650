var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{directives:[{name:"show",rawName:"v-show",value:(_vm.location.lat && _vm.location.lng),expression:"location.lat && location.lng"}],ref:"mapRef",staticStyle:{"width":"100%","height":"30vh"},attrs:{"id":"map","options":{
    zoomControl: true,
    fullscreenControl: true,
    clickableIcons: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    disableDefaultUi: false,
  },"center":_vm.mapOptions.center,"zoom":_vm.mapOptions.zoom},on:{"click":_vm.selectedLocation}},[(_vm.location.lat && _vm.location.lng)?_c('GmapMarker',{attrs:{"position":_vm.location}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }