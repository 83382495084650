<template>
  <app-overlay>
    <app-collapse class="p-0" type="border" accordion>
      <app-collapse-item ref="companycollapse" is-visible title="Firma Bilgileri">
        <validation-observer ref="companyform">
          <company-form v-model="companyData" />
          <repeatedBranchForm v-model="branchItems" />
        </validation-observer>
        <hr />
        <div class="d-flex justify-content-end">
          <app-button icon="ChevronRightIcon" size="md" text="İlerle" @click="checkedValidated(true)" />
        </div>
      </app-collapse-item>
      <app-collapse-item ref="addresscollapse" title="Adres Bilgileri">
        <address-form v-for="(item, index) in branchItems" :key="index" :index="index" :title="item.name" @input="branchItems[index].address = $event" />
        <div class="d-flex justify-content-end">
          <app-button icon="SaveIcon" size="md" text="Kaydet" @click="createCompany" />
        </div>
      </app-collapse-item>
    </app-collapse>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import companyForm from "./companyForm.vue";
import addressForm from "./addressForm.vue";
import repeatedBranchForm from "./repeatedBranchForm.vue";

export default {
  components: {
    companyForm,
    addressForm,
    repeatedBranchForm,
    ValidationObserver,
  },
  data() {
    return {
      branchItems: null,
      companyData: null,
    };
  },
  methods: {
    createCompany() {
      this.$refs.companyform.validate().then((success) => {
        if (success) {
          this.$store.dispatch("newCompany", {
            ...this.companyData,
            officeBranches: this.branchItems,
          });
        } else this.$refs.companycollapse.visible = true;
      });
    },
    checkedValidated(val) {
      this.$refs.addresscollapse.visible = false;
      if (val) {
        this.$refs.companyform.validate().then((success) => {
          if (success) this.$refs.addresscollapse.visible = true;
          else this.$refs.companycollapse.visible = true;
        });
      }
    },
  },
};
</script>

<style></style>
