<template>
  <div class="border rounded p-1 my-50">
    <h5 v-show="title">
      {{ title }}
      <hr />
    </h5>
    <b-row>
      <b-col md="4">
        <provinces v-model="address.province" />
      </b-col>
      <b-col md="4">
        <counties ref="countyinput" v-model="address.county" :province="address.province" />
      </b-col>
      <b-col md="4">
        <districts ref="districtinput" v-model="address.district" :county="address.county" @selected="selectedDistrict" />
      </b-col>
      <b-col md="4">
        <app-input v-model="address.street" name="Sokak / Cadde" label="Sokak / Cadde:" placeholder="Sokak / Cadde" @input="handleInput" />
      </b-col>
      <b-col md="4">
        <app-input v-model="address.buildingNo" placeholder="No:" name="Numara" label="Numara:" @input="handleInput" />
      </b-col>
      <b-col md="4">
        <div class="d-flex gap-5 mt-2">
          <app-button text="Haritada Göster" icon="MapPinIcon" @click="setFullAddress" />
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col v-if="osbSelected" md="4">
        <app-select-input v-model="address.osb" label="OSB:" :reduce="(option) => option._id" name="OSB" placeholder="Seçim yapınız..." select_label="name" :options="osbOptions" />
      </b-col>
      <b-col class="d-flex align-items-center" md="3">
        <b-form-checkbox v-model="osbSelected" class="custom-control-danger" @change="getOsbOptions"> OSB - KSS </b-form-checkbox>
      </b-col>
    </b-row>

    <!-- Google Map Mark -->
    <div v-show="address.district" ref="googleMap">
      <hr />
      <map-vue ref="mapApi" @location-selected="selectedLocation" />
    </div>
  </div>
</template>

<script>
import mapVue from "../address/map.vue";

export default {
  components: { mapVue },
  props: { title: { type: String }, index: { type: Number } },
  data() {
    return {
      osbOptions: [],
      osbSelected: false,
      typingTimeout: null,
      address: {
        province: null,
        county: null,
        district: null,
        street: null,
        buildingNo: null,
        location: {
          lat: null,
          lng: null,
        },
        osb: null,
      },
    };
  },
  watch: {
    address: {
      handler(item) {
        this.$emit("input", {
          ...item,
          province: item.province?._id,
          county: item.county?._id,
          district: item.district?._id,
        });
      },
      deep: true,
    },
    "address.province": function (value) {
      if (value && this.osbSelected) {
        this.getOsbOptions();
      }
    },
  },
  methods: {
    setLocation(address) {
      this.$axios
        .post("/location/get-coordinates", {
          address,
        })
        .then((response) => {
          if (response.data) {
            this.$refs.mapApi.setLocation(response.data);
            this.address.location = response.data;
            this.scrollToElement();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectedDistrict(val) {
      if (val) {
        const { province, county, district } = this.address;
        this.setLocation(`${district.value} ${county.value} ${province.value}`);
      }
    },
    setFullAddress() {
      const { province, county, district, buildingNo, street } = this.address;
      if (province && county && district && buildingNo && street) {
        this.setLocation(`${street} ` + `No:${buildingNo} ${district.value} ${county.value} ${province.value}`);
      }
    },
    handleInput() {
      // Clear the previous timeout
      clearTimeout(this.typingTimeout);

      // Set a new timeout to trigger the event after 1 second of inactivity
      this.typingTimeout = setTimeout(() => {
        this.setFullAddress();
      }, 700);
    },
    scrollToElement() {
      this.$nextTick(() => {
        const el = this.$refs.googleMap;
        if (el) el.scrollIntoView({ behavior: "smooth", block: "center" });
      });
    },
    selectedLocation(location) {
      this.address.location = location;
    },
    getOsbOptions() {
      this.osbOptions = [];
      this.address.osb = null;
      if (this.address?.province && this.osbSelected) {
        this.$store
          .dispatch("getOsbList", this?.address?.province?._id)
          .then((data) => {
            this.osbOptions = data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style></style>
