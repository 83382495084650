<template>
  <div>
    <hr>
    <validation-observer ref="addressform">
      <b-row
        v-for="(item, index) in items"
        :id="index"
        :key="index"
        ref="row"
      >
        <b-col md="4">
          <app-input
            v-model="item.name"
            name="Sicil Adı"
            label="Sicil Adı:"
            placeholder="Sicil Adı..."
            rules="required"
          />
        </b-col>
        <b-col md="4">
          <app-mask-input
            v-model="item.recordNo"
            name="İşyeri Sicil Numarası"
            type="number"
            input-mask="#.####.#.#.#######.##.#.##.#"
            label="İşyeri Sicil Numarası:"
            placeholder="İşyeri Sicil Numarası"
            rules="required|min:28"
          />
        </b-col>
        <b-col :md="index === 0 ? 4 : 3">
          <riskstate-select
            v-model="item.riskState"
            :reduce="(option) => option._id"
            required
          />
        </b-col>
        <b-col
          v-if="index !== 0"
          md="1"
          class="mb-50"
        >
          <app-icon-button
            style="margin-top: 1.9rem"
            icon="XIcon"
            variant="gradient-danger"
            @click="removeItem(index)"
          />
        </b-col>
      </b-row>
    </validation-observer>
    <app-button
      text="Diğer Sicil Ekle"
      icon="PlusIcon"
      size="md"
      @click="repeateAgain"
    />
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  components: { ValidationObserver },
  data() {
    return {
      items: [{
        name: null, recordNo: null, riskState: null, address: {},
      }],
    }
  },
  watch: {
    items: {
      handler(item) {
        this.$emit('input', item)
      },
      deep: true,
    },
  },
  mounted() {
    this.$emit('input', this.items)
  },
  methods: {
    repeateAgain() {
      this.$refs.addressform.validate().then(success => {
        if (success) {
          this.items.push({
            name: null, recordNo: null, riskState: null, address: {},
          })
        }
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
  },
}
</script>
